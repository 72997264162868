import { formatDate } from 'src/core/utilities/general';
import { NotificationProps } from './index.types';
import NotificationDialog from '../../containers/NotificationDialog';
import notificationService from './index.services';
import CheckIcon from 'src/assets/images/Queries/check.svg';
import { useTranslation } from 'react-i18next';

const Notification = ({ notification, notifications }: NotificationProps) => {
  const { openDialog, openDialogNotification, setOpen } = notificationService();
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto py-6 px-4 bg-slate-50 rounded-lg relative shadow-lg">
      <div className="absolute top-2 right-2">
        <span className={`relative  h-3 w-3 ${!notification?.Seen ? 'flex' : 'hidden'}`}>
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-orange-500"></span>
        </span>
      </div>

      <div className="flex items-center justify-between flex-row mb-2">
        <h3 className="text-neutral-700 text-lg font-normal leading-snug truncate flex flex-row items-center gap-2  max-sm:text-sm">
          {notification?.Title} 

          {notification?.Resolved && (<div className="bg-emerald-600 border-emerald-600 w-5 h-5 p-1 rounded-[10px] border  justify-center items-center inline-flex">
                  <img src={CheckIcon} alt="check" />
                </div>) }
          
        </h3>
        <p className="text-zinc-400 text-xs font-normal">{t('Due Date')} : {formatDate(notification?.DateTime ?? '')}</p>
      </div>

      <p className={`text-neutral-700 text-sm font-normal mb-2 truncate max-sm:text-xs`}>{notification?.Description}</p>

      <div className="flex justify-end">
        <button
          onClick={() => openDialogNotification(notification?.DateTime ?? '', notification?.Seen ?? false)}
          className=" h-11 px-4 py-3 bg-white rounded-xl shadow border border-blue-600 justify-center items-center gap-2 inline-flex text-blue-600 text-base font-medium max-sm:text-sm">
          {t('Show')}
        </button>
      </div>
      <NotificationDialog
        open={openDialog}
        onClose={() => setOpen(false)}
        isLoading={false}
        notifications={notifications ?? []}
        notification={notification}
      />
    </div>
  );
};
export default Notification;
