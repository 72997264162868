import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { getRecentFiles } from 'src/modules/Documents/api';
import { useDocumentsContext } from 'src/core/contexts/documents.context';
import { deleteFile, downloadFile, downloadRecentFile, RecentDataType } from 'src/modules/Queries/api';
import { fetchTags, Tag } from 'src/modules/General/api';

export interface Item {
  label: string;
  value: string;
}
export interface FileItem {
  file_name: string;
  id: number;
  timestamp: string;
  note?: string;
  generated_name?: string;
  tags: string[];
}

export interface FileCardProps {
  file: FileItem;
  onPreviewDialog: (fileName: string, fileId: number) => void;
  openDialog: (fileName: string, fileId: number) => void;
  truncateText: (text: string, maxLength: number) => string;
  formatDate: (dateString: string) => string;
}
const DocumentService = () => {
  const { dispatch, state } = useDocumentsContext();
  const { documents, recent_files } = state || {};
  const [items, setItems] = useState<Item[]>([]);
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [allFiles, setAllFiles] = useState<FileItem[]>([]);
  const [allFilesFilter, setAllFilesFilter] = useState<FileItem[]>([]);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [itemSelected, setItemSelected] = useState<Item[]>([]);
  const [tagSelected, setTagSelected] = useState<string[]>([]);
  const [pdfData, setPdfData] = useState<Uint8Array>(new Uint8Array());
  const [loadingDownload, setLoadingdownload] = useState<boolean>(false);

  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);
  const [loadingFilter, setLoadingFilter] = useState<boolean>(false);
  const [loadingReset, setLoadingFilterReset] = useState<boolean>(false);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [nextPage, setNextPage] = useState<boolean>(false);
  const [nextPageFilter, setNextPageFilter] = useState<boolean>(false);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [filterActive, setFilterActive] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [pageFilter, setPageFilter] = useState(1);
  const [pageSizeFilter, setPageSizeFilter] = useState(20);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<{ type: 'screenshot' | 'file'; open: boolean }>({
    type: 'screenshot',
    open: false,
  });

  const [openConfirmationModalDelete, setOpenConfirmationModalDelete] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [fileNameDownload, setFileNameDownload] = useState<string>('');
  const [fileIdDownload, setFileIdDownload] = useState<number>(0);
  const [docId, setDocId] = useState<number | null>(null);

  const openDialog = (fileName: string, fileId: number) => {
    setOpenConfirmationModalDelete(true);
    setFileName(fileName);
    setDocId(fileId);
  };
  const closeDailog = () => {
    setOpenConfirmationModalDelete(false);
    setFileName('');
    setDocId(null);
    setIsLoadingDelete(false);
  };
  const deleteDocument = async () => {
    setIsLoadingDelete(true);
    const data = await deleteFile(docId, fileName, fileName);

    if (data?.data?.error_code === 0) {
      const updatedFiles = allFiles.filter((file) => file.id !== docId);
      const updateFilesFilter = allFilesFilter.filter((file) => file.id != docId)
      // getRecentFilesList();
      setAllFilesFilter(updateFilesFilter)
      setAllFiles(updatedFiles);
      dispatch({ type: 'recent_files', value: updatedFiles });
      setIsLoadingDelete(false);
      closeDailog();
    }
  };
  useEffect(() => {
    getRecentFilesList();
    getTags();
  }, []);

  const documentsList = documents?.length
    ? documents.map((document) => ({ id: document.link, content: document.name }))
    : [];

  const getRecentFilesList = async () => {
    setFilterActive(false);
    setLoadingFiles(true);
    const data = await getRecentFiles({ page_size: 20, page: 1 });

    const files = data?.result?.message;
    const recentFilesListConvert = (await files?.length)
      ? await files.map((file: any) => ({
          content: file?.DocumentName,
          id: file?.DocumentID,
          file_name: file?.DocumentName,
          timestamp: file?.CreatedDateTime,
          blob_path: file?.blob_path,
          generated_name: file?.GeneratedName,
          tags: file?.Tag ?? [],
          note: file?.Note,
        }))
      : [];

    await dispatch({ type: 'recent_files', value: recentFilesListConvert });
    setNextPage(files?.length < pageSize ? false : true);
    setAllFiles(recentFilesListConvert);

    setLoadingFiles(false);
  };
  const onPreviewDialog = (fileName: string, documentId: number) => {
    setFileIdDownload(documentId)
    setFileNameDownload(fileName)
    setOpenConfirmationModal({ type: 'file', open: true });
    downloadDataFile(fileName, documentId);
  };
  const nextPageAction = async (next: boolean) => {
    setLoadingNextPage(true);
    const pageNumber = next ? page + 1 : page - 1;
    setPage(pageNumber);
    const data = await getRecentFiles({ page_size: 20, page: pageNumber });

    const files = data?.result?.message;
    const recentFilesListConvert = (await files?.length)
      ? await files.map((file: any) => ({
          content: file?.DocumentName,
          id: file?.DocumentID,
          file_name: file?.DocumentName,
          timestamp: file?.CreatedDateTime,
          blob_path: file?.blob_path,
          generated_name: file?.GeneratedName,
          tags: file?.Tag ?? [],
          note: file?.Note,
        }))
      : [];

    dispatch({ type: 'recent_files', value: [...allFiles, ...recentFilesListConvert] });
    setAllFiles((prevFiles) => [...prevFiles, ...recentFilesListConvert]);
    setNextPage(files?.length < pageSize ? false : true);

    setLoadingNextPage(false);
  };
  const downloadDataFile = (fileName: string, documentId: number) => {
    try {
      setLoadingdownload(true)
      if (documentId !== 0) {
        downloadRecentFile(fileName, documentId).then((result) => {
          if (typeof result === 'object') {
            const reader = new FileReader();
            reader.onload = async () => {
              if (reader.result instanceof ArrayBuffer) {
                const uint8Array = await new Uint8Array(reader.result);
                if (uint8Array.length === 0) {
                  return;
                }
                setPdfData(new Uint8Array(uint8Array));
              }
            };
            reader.readAsArrayBuffer(result);    
          }
          setTimeout(()=>(
            setLoadingdownload(false)
          ),300)
          
        });
      } else {
        downloadFile(fileName).then((result) => {
          if (typeof result === 'object') {
            const reader = new FileReader();
            reader.onload = () => {
              if (reader.result instanceof ArrayBuffer) {
                const uint8Array = new Uint8Array(reader.result);
                if (uint8Array.length === 0) {
                  return;
                }
                setPdfData(new Uint8Array(uint8Array));
              }
            };
            reader.readAsArrayBuffer(result);
            setLoadingdownload(false)
          }
          setLoadingdownload(false)
        });
      }
    } catch (error) {
      console.log(error)
      setLoadingdownload(false)
    }
   
  };
  const onRetry = () => {
   
    if (fileNameDownload && fileIdDownload) {
      downloadDataFile(fileNameDownload, fileIdDownload);
    }
  };
  const getTags = async () => {
    const data = await fetchTags();
    if (typeof data !== 'string') {
      const tags: Item[] = (await data.tags_list?.length)
        ? data.tags_list.map((tag: Tag) => ({
            label: tag.tag_name,
            value: tag.tag_name,
          }))
        : [];

      setItems(tags);
    }
  };
  const filtredRecentFiles = async (isReset: boolean) => {
    setPageFilter(1);
    if (isReset) {
      setFilterActive(false);
      setIsFilter(false);
      setFromDate(null);
      setToDate(null);
      changeTag([]);
      setAllFilesFilter([]);
      setPageFilter(1);
    } else {
      setFilterActive(true);

      const body: RecentDataType = {
        page_size: 20,
        page: 1,
      };

      setLoadingFilter(true);
      setIsFilter(true);
      if (fromDate) {
        body.start_date = fromDate.format('YYYY-MM-DD');
      }
      if (toDate) {
        body.end_date = toDate.format('YYYY-MM-DD');
      }
      if (tagSelected.length > 0) {
        body.tag = tagSelected;
      }
      const data = await getRecentFiles(body);

      if (data?.error_code === 4) {
        const recentFilesListConvert: any = [];
        setLoadingFiles(false);
        setLoadingFilter(false);
        setLoadingFilterReset(false);
        setAllFilesFilter(recentFilesListConvert);
        setNextPageFilter(false);
      } else {
        const files = data?.result?.message;
        const recentFilesListConvert = files?.length
          ? files.map((file: any) => ({
              content: file?.DocumentName,
              id: file?.DocumentID,
              file_name: file?.DocumentName,
              timestamp: file?.CreatedDateTime,
              blob_path: file?.blob_path,
              generated_name: file?.GeneratedName,
              tags: file?.Tag,
              note: file?.Note,
            }))
          : [];

        // dispatch({ type: 'recent_files', value: recentFilesListConvert });
        setLoadingFiles(false);
        setLoadingFilter(false);
        setLoadingFilterReset(false);

        setAllFilesFilter(recentFilesListConvert);
        setNextPageFilter(files?.length < pageSize ? false : true);
      }
    }
  };
  const nextPageFilterAction = async () => {
    setLoadingFilter(true);
    setIsFilter(true);
    const pageNumber = pageFilter + 1;
    setPageFilter(pageNumber);
    const body: RecentDataType = {
      page_size: 20,
      page: pageNumber,
    };
    if (fromDate) {
      body.start_date = fromDate.format('YYYY-MM-DD');
    }
    if (toDate) {
      body.end_date = toDate.format('YYYY-MM-DD');
    }
    if (tagSelected.length > 0) {
      body.tag = tagSelected;
    }
    const data = await getRecentFiles(body);
    if (data?.error_code === 4) {
      const recentFilesListConvert: any = [];
      setLoadingFiles(false);
      setLoadingFilter(false);
      setLoadingFilterReset(false);
      setAllFilesFilter(recentFilesListConvert);
      setNextPageFilter(false);
    } else {
      const files = data?.result?.message;
      const recentFilesListConvert = files?.length
        ? files.map((file: any) => ({
            content: file?.DocumentName,
            id: file?.DocumentID,
            file_name: file?.DocumentName,
            timestamp: file?.CreatedDateTime,
            blob_path: file?.blob_path,
            generated_name: file?.GeneratedName,
            tags: file?.Tag,
            note: file?.Note,
          }))
        : [];

      // dispatch({ type: 'recent_files', value: recentFilesListConvert });
      setLoadingFiles(false);
      setLoadingFilter(false);
      setLoadingFilterReset(false);
      setAllFilesFilter((prevFiles) => [...prevFiles, ...recentFilesListConvert]);
      setNextPageFilter(files?.length < pageSize ? false : true);
    }
  };
  const onCloseModal = () => {
    setPdfData(new Uint8Array());
    setOpenConfirmationModal({ ...openConfirmationModal, open: false });
  };
  const changeTag = (value: Item[]) => {
    setItemSelected(value);
    setTagSelected(value.map((item) => item.value));
  };
  return {
    documents,
    documentsList,
    recent_files,
    allFiles,
    isModalOpen: openConfirmationModal.open,
    onCloseModal,
    onPreviewDialog,
    pdfData,
    openDialog,
    closeDailog,
    openConfirmationModalDelete,
    deleteDocument,
    isLoadingDelete,
    loadingFiles,
    items,
    fromDate,
    toDate,
    itemSelected,
    tagSelected,
    changeTag,
    filtredRecentFiles,
    setFromDate,
    setToDate,
    isFilter,
    loadingFilter,
    loadingReset,
    nextPage,
    nextPageFilter,
    nextPageAction,
    loadingNextPage,
    allFilesFilter,
    filterActive,
    nextPageFilterAction,
    loadingDownload,
    onRetry
  };
};

export default DocumentService;
