import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useNotificationsContext } from 'src/core/contexts/notifications.context';
import {
  NotificationData,
  RecentNotificationBody,
  fetchNotifications,
  getRecentNotifications,
} from 'src/modules/Notifications/api';

const useNotificationPage = () => {
  const { dispatch } = useNotificationsContext();
  const [notificationsList, setNotificationsList] = useState<NotificationData[] | null>(null);
  const [unRead, setUnRead] = useState<number>(0);
  const [read, setRead] = useState<number>(0);
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [isLoadingData, setIsLodingDate] = useState(false);
  useEffect(() => {
    getDefaultNotifications();
  }, []);

  const getDefaultNotifications = async () => {
    setIsLodingDate(true);
    const from = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate());
    const to = new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
    setFromDate(dayjs(from.toLocaleDateString('en-CA')));
    setToDate(dayjs(to.toLocaleDateString('en-CA')));
    try {
      await fetchNotifications();

      const body = {
        start_date: from.toLocaleDateString('en-CA'),
        end_date: to.toLocaleDateString('en-CA'),
        number_of_notifications: 20000,
      };

      setTimeout(() => {
        onGetNotificationsByDateRang(body);
      }, 2000);
    } catch (error) {
      setIsLodingDate(false);
      console.log(error);
    }
  };

  const onGetNotificationsByDateRang = async (data: RecentNotificationBody) => {
    try {
      dispatch({ type: 'loading', value: true });
      setIsLodingDate(true);
      const allNotifications = await getRecentNotifications(data);
     
      dispatch({ type: 'notifications', value: allNotifications.result?.message });
      setNotificationsList(allNotifications.result?.message);
      dispatch({ type: 'loading', value: false });
      setIsLodingDate(false);
    } catch (error) {
      setIsLodingDate(false);
      dispatch({ type: 'loading', value: false });
      console.error('Error fetching notifications by date range:', error);
    }
  };
  const getStatusNotifications = (notifications: NotificationData[]) => {
    if (notifications?.length) {
      const read = notifications.filter((notification: NotificationData) => {
        return notification.Seen === true;
      });
      const unRead = notifications.filter((notification: NotificationData) => {
        return notification.Seen === false;
      });
      setRead(read.length);
      setUnRead(unRead.length);
    } else {
      setRead(0);
      setUnRead(0);
    }
  };
  return {
    onGetNotificationsByDateRang,
    notificationsList,
    read,
    unRead,
    getStatusNotifications,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    isLoadingData,
  };
};

export default useNotificationPage;
