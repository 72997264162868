import request from 'src/core/api/axios';
import { ChangeEmailsDataType } from '../containers/EmailChangePassword/index.types';

//TYPES
export type AddEmailsDataType = {
  host: string;
  address: string;
  password: string;
  processed_emails_count: number;
  activate: boolean;
};

type RecentEmailDataType = {
  address: string;
  number?: number;
  page_size?: number;
  page?: number;
};
interface InitialEmailsRespond {
  error_code: number;
  message: string;
}
export interface EmailAccount {
  user_id: number;
  host: string;
  address: string;
  password: string;
  uid: number;
  account_id: number;
  activate: boolean;
  processed_emails_count: number;
  is_connected?: boolean
}
export interface listAccountEmails {
  data: EmailAccount[];
}
export interface RecentEmail {
  Attachmnet: string;
  EmailContent: string;
  EmailContentRaw: string;
  EmailID?: number;
  EmailDate: string;
  EmailSubject: string;
  Sender: string;
  UserID: number | null;
}
export interface RecentEmailsRespond {
  error_code: string;
  data: RecentEmail[];
}

// Email
export async function addEmails(formData: AddEmailsDataType): Promise<any> {
  try {
    const { data } = await request.post<any>('/add_email_account', formData);
    return data;
  } catch (error) {}
}

export async function removeEmail(address: string): Promise<any> {
  try {
    const { data } = await request.post<any>('/remove_email_account', { address });
    return data;
  } catch (error) {}
}

export async function changePasswordEmailAccount(body: ChangeEmailsDataType): Promise<any> {
  try {
    const { data } = await request.post<any>('/update-email-password', body);
    return data;
  } catch (error) {
    return 'Error'
  }
 
}

export async function deactivateEmail(address: string): Promise<any> {
  try {
    const { data } = await request.post<any>('/deactivate-email-account', { address });
    return data;
  } catch (error) {
    return 'Error'
  }
 
}

export async function initEmailParser(): Promise<InitialEmailsRespond | any> {
  try {
    const { data } = await request.get<InitialEmailsRespond>('/init_email_parser');
    return data;
  } catch (error) {}
}

export async function processEmail(): Promise<any> {
  try {
    const { data } = await request.post<any>('/process_mails', {});
    return data;
  } catch (error) {}
}

export async function activateEmail(address: string): Promise<any> {
  const { data } = await request.post<any>('/activate-email-account', {address});
  return data;
}

export async function retriveAllEmails(): Promise<listAccountEmails | string> {
  try {
    const { data } = await request.get<listAccountEmails>('/get_email_accounts');
    return data;
  } catch (error) {
    return '';
  }
}

export async function retriveRecentEmails({
  number,
  ...rest
}: RecentEmailDataType): Promise<RecentEmailsRespond | any> {
  try {
    const { data } = await request.post<RecentEmailsRespond>('/get_recent_emails', { ...rest });
    return data;
  } catch (error) {}
}

export async function downloadAttachmentOfEmail(filename: string): Promise<any> {
  const url = `/attachments/${filename}`;
  const { data } = await request.get<any>(url);
  return data;
}
