import React from 'react';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import Icon from 'src/modules/General/components/Icon';
import { EmailProps, EmailsListProps } from './index.types';
import useEmailsLeftColumn from 'src/pages/Emails/LeftColumn/index.services';
import DotsIcon from 'src/assets/images/Emails/dots-horizontal.svg';
import { useTranslation } from 'react-i18next';

const EmailsList = ({ emails, onEmailClick, onRemoveEmail, onActiveOrDeactiveEmail, onChangePassword }: EmailsListProps) => {
  const { t } = useTranslation();
  const [openActionsMenu, setOpenActionsMenu] = React.useState<null | { anchorEl: HTMLElement, email: string }>(null);
  const { pageSize } = useEmailsLeftColumn();
  
  const handleRemoveEmail = (event: React.MouseEvent<HTMLElement>, email: string) => {
    event.stopPropagation();
    onRemoveEmail?.(email);
    setOpenActionsMenu(null);
  };

  const handleActiveOrDeactiveEmail = (event: React.MouseEvent<HTMLElement>, active: boolean, email: string) => {
    event.stopPropagation();
    onActiveOrDeactiveEmail?.(active, email);
    setOpenActionsMenu(null);
  };

  const handleChangePassword = (event: React.MouseEvent<HTMLElement>, email: string) => {
    event.stopPropagation();
    onChangePassword?.(email);
    setOpenActionsMenu(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, email: string) => {
    event.stopPropagation();
    setOpenActionsMenu(openActionsMenu?.email === email ? null : { anchorEl: event.currentTarget, email });
  };

  return (
    <div className="w-full max-h-calc-80 overflow-y-auto justify-between items-center  inline-flex flex-col gap-y-4">
      {!!emails?.length &&
        emails.map((item: EmailProps, index: number) => (
          <div key={index} className="w-full h-auto bg-stone-50 rounded-2xl border border-gray-200 ">
            <div className="w-full h-[72px] px-4 py-6 bg-stone-50 rounded-2xl border border-gray-200 justify-between items-center gap-4 inline-flex">
              <div className="w-full grid grid-cols-4 gap-3 items-center justify-between flex-row">
                <div
                  onClick={() => onEmailClick?.(item.email, item.count, pageSize)}
                  className="cursor-pointer col-span-3 flex items-center justify-start gap-x-0.5 flex-row">
                   <div className={`w-3 h-3 ${item.active && item?.is_connected ? "bg-emerald-600" : "bg-red-600"}  rounded-full`} />
                  
                  <p className="truncate w-full">{item.email}</p>
                </div>
                <div className="col-span-1 flex items-center justify-between gap-x-4">
                  {item.count >= 0 && (
                    <Tooltip
                      title={t('Number of processed email')}
                      className="w-6 h-6 p-1 bg-orange-200 rounded-full flex-col justify-center items-center inline-flex">
                      <span className="text-neutral-950 text-xs font-normal">{item.count}</span>
                    </Tooltip>
                  )}
                  <div className="w-6 h-6 ">
                    <button
                      name="three-dots"
                      className="w-6 h-6 cursor-pointer justify-center items-center inline-flex"
                      onClick={(e) => handleClick(e, item.email)}>
                      <img src={DotsIcon} alt="" />
                    </button>
                    <Menu
                      anchorEl={openActionsMenu?.email === item.email ? openActionsMenu.anchorEl : null}
                      open={openActionsMenu?.email === item.email}
                      onClose={(event: React.MouseEvent<HTMLElement>) => {
                        event.stopPropagation();
                        setOpenActionsMenu(null);
                      }}>
                      <MenuItem onClick={(e) => handleRemoveEmail(e, item.email)}>
                        <Icon name="trash" className="mr-2" />
                        {t('Remove')}
                      </MenuItem>
                      <MenuItem onClick={(e) => handleActiveOrDeactiveEmail(e, item.active, item.email)}>
                        <Icon name={item.active ? 'deactive' : 'active'} className="mr-2" />
                        {item.active ? 'Deactive' : 'Active'}
                      </MenuItem>
                      {!item?.is_connected && (
                        <MenuItem onClick={(e) => handleChangePassword(e, item.email)}>
                          <Icon name="password" className="mr-2 w-8" />
                          Change Password
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default EmailsList;

