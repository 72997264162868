import { EmailsProvider } from 'src/core/contexts/emails.context';
import Header from 'src/modules/General/containers/Layout/Header';
import HeaderMenu from './HeaderMenu';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import useEmailsPage from './index.services';

const Emails: React.FC = () => {
  const {
    emailsList,
    onAddEmail,
    isLoading,
    onRemoveEmail,
    onRemoveEmailClick,
    updateEmailsList,
    showSnackbar,
    handleCloseSnackbar,
    openEmailModal,
    setOpenEmailModal,
    openConfirmationModal,
    closeConfirmationModal,
    onActiveOrDeactiveEmail,
    onDeactivateEmail,
    isRemoveAction,
    messageConfirmationModal,
    isOpenConfirmationModal,
    setOpenPasswordModal,
    openChangePasswordModal,
    changePasswordEmail,
    handleOpenCahngePasswordModal,
    handleCloseChangePasswordEmailModal,
    emailAddress
  } = useEmailsPage();
  return (
    <EmailsProvider>
      <Header />
      <div className="w-sideBar flex  h-full max-lg:h-calc-120 max-lg:w-full max-lg:block max-lg:gap-0 max-sm:p-2">
        <div className="hidden w-full h-15 mb-2 max-lg:block">
          <HeaderMenu
           emailAddress={emailAddress}
           handleOpenCahngePasswordModal = {handleOpenCahngePasswordModal}
           handleCloseChangePasswordEmailModal = {handleCloseChangePasswordEmailModal}
           openChangePasswordModal={openChangePasswordModal}
           changePasswordEmail={changePasswordEmail}
           setOpenPasswordModal={setOpenPasswordModal}
          isOpenConfirmationModal={isOpenConfirmationModal}
          isRemoveAction={isRemoveAction}
          messageConfirmationModal={messageConfirmationModal}
          onActiveOrDeactiveEmail={onActiveOrDeactiveEmail}
          onDeactivateEmail={onDeactivateEmail}
            openConfirmationModal={openConfirmationModal}
            closeConfirmationModal={closeConfirmationModal}
            openEmailModal={openEmailModal}
            setOpenEmailModal={setOpenEmailModal}
            emailsList={emailsList}
            onAddEmail={onAddEmail}
            isLoading={isLoading}
            onRemoveEmail={onRemoveEmail}
            onRemoveEmailClick={onRemoveEmailClick}
            showSnackbar={showSnackbar}
            updateEmailsList={updateEmailsList}
            handleCloseSnackbar={handleCloseSnackbar}
          />
        </div>
        <div className="w-calc-384 px-6 py-8 bg-gray-200  max-lg:w-full max-lg:h-calc-68 max-sm:p-1">
          <LeftColumn />
        </div>

        <div className="w-96 max-lg:hidden">
          <RightColumn
          emailAddress={emailAddress}
          handleOpenCahngePasswordModal = {handleOpenCahngePasswordModal}
          handleCloseChangePasswordEmailModal = {handleCloseChangePasswordEmailModal}
          openChangePasswordModal={openChangePasswordModal}
          changePasswordEmail={changePasswordEmail}
          setOpenPasswordModal={setOpenPasswordModal}
          isOpenConfirmationModal={isOpenConfirmationModal}
          isRemoveAction={isRemoveAction}
          messageConfirmationModal={messageConfirmationModal}
          onActiveOrDeactiveEmail={onActiveOrDeactiveEmail}
          onDeactivateEmail={onDeactivateEmail}
            openConfirmationModal={openConfirmationModal}
            closeConfirmationModal={closeConfirmationModal}
            openEmailModal={openEmailModal}
            setOpenEmailModal={setOpenEmailModal}
            emailsList={emailsList}
            isLoading={isLoading}
            onRemoveEmail={onRemoveEmail}
            onRemoveEmailClick={onRemoveEmailClick}
            showSnackbar={showSnackbar}
            onAddEmail={onAddEmail}
            updateEmailsList={updateEmailsList}
            handleCloseSnackbar={handleCloseSnackbar}
          />
        </div>
      </div>
    </EmailsProvider>
  );
};

export default Emails;
