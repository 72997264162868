import DropDown from 'src/modules/Emails/containers/DropDown';
import Icon from 'src/modules/General/components/Icon';
import useEmailsLeftColumn from '../RightColumn/index.services';
import ConfirmationModal from 'src/modules/General/containers/ConfirmationModal';
import Loading from 'src/modules/General/components/Loading';
import EmailAuthModal from 'src/modules/Emails/containers/EmailAuthModal';
import Snackbar from 'src/modules/General/components/Snackbar';
import { EmailProps } from 'src/modules/Emails/containers/EmailsList/index.types';
import { AddEmailsDataType } from 'src/modules/Emails/api';
import { useTranslation } from 'react-i18next';
import { ChangeEmailsDataType } from 'src/modules/Emails/containers/EmailChangePassword/index.types';
import EmailChangePasswordModal from 'src/modules/Emails/containers/EmailChangePassword';
export interface SnackbarProps {
  display: boolean;
  color: string;
  message: string;
}
interface HeaderMenuProps {
  emailsList: EmailProps[];
  isLoading: boolean;
  onRemoveEmailClick: (email: string) => void;
  onRemoveEmail: () => Promise<void>;
  updateEmailsList: (call: boolean, processCal:boolean) => Promise<void>;
  onAddEmail: (formData: AddEmailsDataType) => Promise<void>;
  setOpenEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
  openConfirmationModal: {
    name: 'remove' | 'deactivate';
    open: boolean;
  };
  closeConfirmationModal: () => void;
  showSnackbar: SnackbarProps;
  handleCloseSnackbar: () => void;
  openEmailModal: boolean;
  onDeactivateEmail: () => Promise<void>;
  onActiveOrDeactiveEmail: (isActive: boolean, email: string) => Promise<void>;
  isRemoveAction: boolean;
  messageConfirmationModal:string;
  isOpenConfirmationModal: boolean;
  setOpenPasswordModal: React.Dispatch<React.SetStateAction<boolean>>
  openChangePasswordModal: boolean,
  changePasswordEmail: (formData: ChangeEmailsDataType) => void,
  handleOpenCahngePasswordModal: (address: string) => void,
  handleCloseChangePasswordEmailModal: () => void,
  emailAddress:string
}

const HeaderMenu = ({
  emailsList,
  onAddEmail,
  isLoading,
  onRemoveEmail,
  onRemoveEmailClick,
  showSnackbar,
  updateEmailsList,
  handleCloseSnackbar,
  openConfirmationModal,
  closeConfirmationModal,
  openEmailModal,
  setOpenEmailModal,
  onActiveOrDeactiveEmail,
  onDeactivateEmail,
  isOpenConfirmationModal,
  isRemoveAction,
  messageConfirmationModal,
  openChangePasswordModal,
  setOpenPasswordModal,
  changePasswordEmail,
  handleCloseChangePasswordEmailModal,
  handleOpenCahngePasswordModal,
  emailAddress
  
}: HeaderMenuProps) => {
  const { onEmailClick } = useEmailsLeftColumn();
  const { t } = useTranslation();

 
  return (
    <div className="w-full h-15 bg-white rounded-md flex items-center justify-between p-1">
      <div className="h-full flex items-center justify-start gap-1">
        <Icon
          onClick={() => setOpenEmailModal(true)}
          name="add"
          className="w-full flex items-center justify-center flex-row bg-primary p-2 rounded-lg cursor-pointer hover:bg-dark-blue-3"
        />
        <Icon
          onClick={() => updateEmailsList(false, true)}
          name="refresh"
          className="w-full flex items-center justify-center flex-row bg-primary p-2 rounded-lg cursor-pointer hover:bg-dark-blue-3"
          width={20}
          height={20}
        />
      </div>
      <div className="h-full flex items-center justify-start gap-x-2">
        <p className="max-sm:text-sm">{t('Emails')} :</p>
        <DropDown onEmailClick={onEmailClick} onRemoveEmail={onRemoveEmailClick}  onActiveOrDeactiveEmail={onActiveOrDeactiveEmail} onChangePassword={handleOpenCahngePasswordModal} emails={emailsList} />
      </div>
      <EmailAuthModal
        open={openEmailModal}
        onClose={() => setOpenEmailModal(false)}
        onSubmitAddEmail={onAddEmail}
        isLoading={isLoading}
      />
        <EmailChangePasswordModal
      open ={openChangePasswordModal}
      onClose={handleCloseChangePasswordEmailModal}
      onSubmitChangePasswordEmail={changePasswordEmail}
      isLoading = {isLoading}
      address={emailAddress}
      />
      {showSnackbar.display && (
        <Snackbar
          color={showSnackbar.color}
          open={showSnackbar.display}
          onClose={handleCloseSnackbar}
          message={showSnackbar?.message}
        />
      )}
      <ConfirmationModal
        open={isOpenConfirmationModal}
        onClose={closeConfirmationModal}
        text={messageConfirmationModal}
        buttons={[
          {
            children:isRemoveAction ? t('Remove') : t('Deactivate'),
            variant: 'contained',
            color: 'error',
            onClick:  isRemoveAction ? onRemoveEmail : onDeactivateEmail,
            endIcon: isLoading && <Loading variant="circle" size={20} color="inherit" />,
          },
          {
            children: t('Cancel'),
            variant: 'contained',
            color: 'success',
            onClick: closeConfirmationModal,
          },
        ]}
      />
    </div>
  );
};
export default HeaderMenu;
