import { useEffect, useState } from 'react';

import { useEmailsContext } from 'src/core/contexts/emails.context';
import {
  AddEmailsDataType,
  EmailAccount,
  RecentEmail,
  activateEmail,
  addEmails,
  deactivateEmail,
  processEmail,
  removeEmail,
  retriveAllEmails,
  retriveRecentEmails,
} from 'src/modules/Emails/api';
import { EmailProps } from 'src/modules/Emails/containers/EmailsList/index.types';

//TYPES

interface Snackbar {
  display: boolean;
  color: string;
  message: string;
}

export interface Email {
  id: number;
  sender: string;
  subject: string;
  date: string;
  content: string;
  contentRow: string;
  attachment: string;
}

//Convertors
const convertToEmailsList = (list: Array<EmailAccount>) => {
  return list.length
    ? list?.map((email: EmailAccount) => ({
        id: email.uid,
        email: email.address,
        host: email.host,
        count: email.processed_emails_count,
        active: email.activate,
      }))
    : [];
};

const convertToRecentEmails = (list: Array<RecentEmail>) => {
 
  return list?.length
    ? list.map((recent: RecentEmail, index: number) => ({
        id: recent.EmailID ?? index,
        sender: recent.Sender,
        subject: recent.EmailSubject,
        date: recent.EmailDate,
        content: recent.EmailContent,
        contentRow: recent.EmailContentRaw,
        attachment: recent.Attachmnet,
      }))
    : [];
};
const PAGE_SIZE = 30;

const useEmailsRightColumn = () => {
  const unknownError = 'An unknown error occurred.';
  const { dispatch, state } = useEmailsContext();
  const { emails } = state || {};
  const [openEmailModal, setOpenEmailModal] = useState(false);

  const [page, setPage] = useState(1);

  const [showSnackbar, setShowSnackbar] = useState<Snackbar>({ display: false, color: '', message: '' });
  //FIXME: remove any
  // const addedEmails = (useLoaderData() as EmailAccount[]) || [];
  const [emailsList, setEmailsList] = useState<EmailProps[]>([]);
  const [emailName, setEmailName] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState<{ name: 'remove' | 'deactivate'; open: boolean }>({
    name: 'remove',
    open: false,
  });
  const [allEmailsCount, setAllEmailsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const updateEmailsList = async () => {
    const process = await processEmail();
    setShowSnackbar({ display: true, color: 'info', message: process.message });
    const data = await retriveAllEmails();
    if (typeof data !== 'string') {
      setEmailsList(convertToEmailsList(data.data));
    }
  };

  const onEmailClick = async (address: string, process_number: number, number = 20) => {
  
    if (process_number) {
      dispatch({ type: 'loading', value: true });
      try {
        setAllEmailsCount(process_number);

        let recentEmailsList: Email[];
        const recentEmails = await retriveRecentEmails({ address, number, page_size: PAGE_SIZE, page: 1 });

        if (recentEmails.error_code === '0' || recentEmails.error_code === 0) {
          recentEmailsList = convertToRecentEmails(recentEmails.data);
        } else {
          recentEmailsList = [];
        }

        dispatch({ type: 'emails', value: recentEmailsList });
        dispatch({ type: 'showContent', value: false });
        dispatch({ type: 'emailAddress', value: address });
        dispatch({ type: 'count', value: process_number });
        dispatch({ type: 'pageSize', value: number });
        dispatch({ type: 'loading', value: false });
        dispatch({ type: 'nextPage', value: recentEmailsList.length < 20 ? false : true });
      } catch {
        dispatch({ type: 'loading', value: false });
      }
    } else {
      setShowSnackbar({ display: true, color: 'error', message: 'There is no processed email from this account!' });
    }
  };

  const nextPageAction = async (address: string) => {
    const pageNumber = page + 1;
    setPage(pageNumber);
    let recentEmailsList: Email[];
    dispatch({ type: 'loadingNextPage', value: true });
    const recentEmails = await retriveRecentEmails({ address, page_size: PAGE_SIZE, page: pageNumber });
   
    if (recentEmails.error_code === '0' || recentEmails.error_code === 0) {
      recentEmailsList = convertToRecentEmails(recentEmails.data);
    } else {
      recentEmailsList = [];
    }

    dispatch({ type: 'emails', value: [...emails, ...recentEmailsList] });
    dispatch({ type: 'nextPage', value: recentEmails?.data?.length < 20 ? false : true });
    dispatch({ type: 'loadingNextPage', value: false });
  };
  const initProcess = async () => {
    const process = await processEmail();
    if (process?.error_code) return;
    setTimeout(async () => {
      const addedEmails = await retriveAllEmails();
      if (typeof addedEmails !== 'string') {
        setEmailsList(convertToEmailsList(addedEmails.data));
      }
    }, 50000);
  };

  useEffect(() => {
    setInterval(() => {
      initProcess();
    }, 1000000);
  }, []);

  const onRemoveEmailClick = (email: string) => {
    setEmailName(email);
    setOpenConfirmationModal({ name: 'remove', open: true });
  };
  const onRemoveEmail = async () => {
    setIsLoading(true);
    try {
      const response = await removeEmail(emailName);

      if (response?.error_code == 0) {
        updateEmailsList();
        setShowSnackbar({ display: true, color: 'success', message: response?.message });
        dispatch({ type: 'emails', value: [] });
        dispatch({ type: 'showContent', value: false });
        dispatch({ type: 'emailAddress', value: '' });
        dispatch({ type: 'count', value: 0 });
      } else {
        setShowSnackbar({ display: true, color: 'error', message: response?.message });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setShowSnackbar({ display: true, color: 'error', message: error.message });
      } else {
        console.error('An unknown error occurred.');
        setShowSnackbar({ display: true, color: 'error', message: unknownError });
      }
    }
    setIsLoading(false);
    closeConfirmationModal();
  };
  const onAddEmail = async (formData: AddEmailsDataType) => {
    setIsLoading(true);
    try {
      const response = await addEmails(formData);

      if (response.error_code === 0) {
        setIsLoading(false);
        updateEmailsList();
        setShowSnackbar({ display: true, color: 'success', message: response?.message });
      } else {
        setShowSnackbar({ display: true, color: 'error', message: response?.message });
      }
    } catch (error: unknown) {
      setIsLoading(false);
      if (error instanceof Error) {
        setShowSnackbar({ display: true, color: 'error', message: error.message });
      } else {
        console.error('An unknown error occurred.');
        setShowSnackbar({ display: true, color: 'error', message: unknownError });
      }
    }
    setOpenEmailModal(false);
    setIsLoading(false);
  };
  const onActiveOrDeactiveEmail = async (isActive: boolean, email: string) => {

    try {
      if (isActive) {
       
        setEmailName(email);
        setOpenConfirmationModal({ name: 'deactivate', open: true });
      } else {
        const response = await activateEmail(email);
        if (response?.error_code === 0) {
          updateEmailsList();
          setShowSnackbar({ display: true, color: 'success', message: response?.message });
        } else {
          setShowSnackbar({ display: true, color: 'error', message: response?.message });
        }
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setShowSnackbar({ display: true, color: 'error', message: error.message });
      } else {
        console.error('An unknown error occurred.');
        setShowSnackbar({ display: true, color: 'error', message: unknownError });
      }
    }
  };

  const onDeactivateEmail = async () => {
    setIsLoading(true);
    try {
      const response = await deactivateEmail(emailName);
      if (response?.error_code === 0) {
        updateEmailsList();
        setShowSnackbar({ display: true, color: 'success', message: response?.message });
      } else {
        setShowSnackbar({ display: true, color: 'error', message: response?.message });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setShowSnackbar({ display: true, color: 'error', message: error.message });
      } else {
        console.error('An unknown error occurred.');
        setShowSnackbar({ display: true, color: 'error', message: unknownError });
      }
    }
    setIsLoading(false);
    closeConfirmationModal();
  };

  const handleCloseSnackbar = () => setShowSnackbar({ display: false, message: '', color: '' });

  const closeConfirmationModal = () => {
    setIsLoading(false);
    setOpenConfirmationModal({ ...openConfirmationModal, open: false });
  };

  return {
    openEmailModal,
    setOpenEmailModal,
    emailsList,
    onEmailClick,
    onRemoveEmailClick,
    onRemoveEmail,
    onActiveOrDeactiveEmail,
    onDeactivateEmail,
    onAddEmail,
    showSnackbar,
    handleCloseSnackbar,
    openConfirmationModal,
    closeConfirmationModal,
    isLoading,
    updateEmailsList,
    setIsLoading,
    allEmailsCount,
    nextPageAction,
    openModal: openConfirmationModal.open
  };
};

export default useEmailsRightColumn;
