import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import FileIcon from 'src/assets/images/Documents/file.svg';
import TrashIcon from 'src/assets/images/Documents/trash.svg';
import EyeIcon from 'src/assets/images/Documents/eye.svg';
import { FileCardProps } from 'src/pages/Documents/index.services';
import { useTranslation } from 'react-i18next';

const FileCard: React.FC<FileCardProps> = ({ file, onPreviewDialog, openDialog, truncateText, formatDate,selectedFileIds,setSelectedFileIds,setSelectedAllDocuments ,isAllSelected}) => {
  const { t } = useTranslation();
  const handleCheckboxChange = () => {
    const fileData = { filename: file.file_name, document_id: file.id };
    
    // Check if the file is already selected
    const isSelected = selectedFileIds.some(selected => selected.document_id === file.id);

    // Create a new array of selected files
    const newSelectedFiles = isSelected 
      ? selectedFileIds.filter(selected => selected.document_id !== file.id) // Remove from selection
      : [...selectedFileIds, fileData]; // Add to selection

    setSelectedFileIds(newSelectedFiles); 
    setSelectedAllDocuments(isAllSelected());
  };
  return (
    <div className="w-full py-6 px-4 bg-white rounded flex flex-col gap-y-4 relative max-sm:pr-3">
      <div onClick={() => onPreviewDialog(file.file_name, file.id)} className="flex items-start gap-x-6 cursor-pointer">
        <div className="w-16 h-16 rounded-md">
          <img src={FileIcon} alt="file" className="w-full h-full object-contain" />
        </div>
        <div className="w-full flex flex-col gap-2">
          <p className="text-neutral-700 text-base font-normal leading-tight  max-sm:text-sm">{file.file_name}</p>
          <p className="text-gray-400 text-base font-normal leading-tight max-sm:text-sm">
            {t('Add')} : {formatDate(file.timestamp)}
          </p>
          {file.note &&
            (file?.note.length > 80 ? (
              <Tooltip title={file.note} arrow>
                <p className="text-gray-400 text-base font-normal leading-tight max-sm:text-sm">
                  {t('Note')} : {truncateText(file.note, 80)}
                </p>
              </Tooltip>
            ) : (
              <p className="text-gray-400 text-base font-normal leading-tight max-sm:text-sm">{t('Note')} : {file.note}</p>
            ))}

          {file?.generated_name && (
            <p className="text-gray-400 text-base font-normal leading-tight max-sm:text-sm">
              {t('Generated Name')} : {file.generated_name}
            </p>
          )}

          <div className=" flex flex-row flex-wrap gap-1">
            {t('Tags')} :
            {file?.tags?.length > 0 ? (
              file?.tags.map((tag: string, index) => (
                <p key={index} className="p-1 rounded-md bg-blue-tag text-base font-normal leading-tight text-white">
                  {tag}
                </p>
              ))
            ) : (
              <p className="text-gray-400 text-base font-normal leading-tight">{t('No tags')}</p>
            )}
            {/* {file?.tags?.length > 0 ? file?.tags.join(', ') : 'No tags'} */}
          </div>
        </div>
      </div>
 
      <div className="absolute top-0 right-0 flex flex-row gap-1">
        {/* <IconButton aria-label="eye" onClick={() => onPreviewDialog(file.file_name, file.id)}>
          <img src={EyeIcon} alt="" />
        </IconButton> */}
        <label className="block relative pl-8 mb-3 top-2 cursor-pointer text-2xl select-none">
      <input
        type="checkbox"
        checked={selectedFileIds.some(selected => selected.document_id === file.id)}
        onChange={handleCheckboxChange}
        className="absolute opacity-0 cursor-pointer h-0 w-0"
      />
      <span
        className={`absolute top-0 left-0 h-6 w-6 bg-gray-200 rounded transition-all duration-200
           ${selectedFileIds.some(selected => selected.document_id === file.id) ? 'bg-orange-400' : 'bg-gray-200 hover:bg-gray-300'}`}
      >
        <span
          className={`absolute left-2 top-1 w-1.5 h-3.5 border-white border-r-2 border-b-2 transform
            ${selectedFileIds.some(selected => selected.document_id === file.id) ? 'block rotate-45' : 'hidden'}`}
        />
      </span>
    </label>
        
        <IconButton aria-label="delete" onClick={() => openDialog(file.file_name, file?.id)}>
          <img src={TrashIcon} alt="" />
        </IconButton>
      </div>
    </div>
  );
};

export default FileCard;
