import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import PropTypes from 'prop-types';
import { NoteActionsProps } from "./index.types";
import EditIcon from 'src/assets/images/Note/edit.svg';
import TrashIcon from 'src/assets/images/Note/trash.svg';
import MoreIcon from 'src/assets/images/Note/more.svg';
const NoteActions: React.FC<NoteActionsProps> = ({ onEdit, onDelete, note }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <img src={MoreIcon} alt="More" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem onClick={() => { onEdit(note,''); handleClose(); }}>
          <img src={EditIcon} alt="Edit" style={{ marginRight: '8px' }} />
          Edit
        </MenuItem>
        <MenuItem onClick={() => { onDelete(note.NoteID); handleClose(); }}>
          <img src={TrashIcon} alt="Delete" style={{ marginRight: '8px' }} />
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};
NoteActions.propTypes = {
  note: PropTypes.shape({
    Label: PropTypes.string.isRequired, 
    NoteID: PropTypes.number.isRequired,
    Note: PropTypes.string.isRequired,
    CreatedDateTime: PropTypes.string.isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default NoteActions;
