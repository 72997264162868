import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'src/core/hooks/useAuth';
import Logo from 'src/modules/General/components/Logo';
import QuerisIcon from 'src/assets/images/SideBar/queries.svg';
import QuerisIconActive from 'src/assets/images/SideBar/queries-active.svg';
import InboxIcon from 'src/assets/images/SideBar/Email.svg';
import InboxIconActive from 'src/assets/images/SideBar/Email-active.svg';
import NotificationIcon from 'src/assets/images/SideBar/notification.svg';
import NotificationIconActive from 'src/assets/images/SideBar/notification-active.svg';
import SettingIcon from 'src/assets/images/SideBar/settings.svg';
import ExitIcon from 'src/assets/images/SideBar/exit.svg';
import SettingIconActive from 'src/assets/images/SideBar/settings-active.svg';
import NoteIcon from 'src/assets/images/SideBar/note.svg';
import NoteActiveIcon from 'src/assets/images/SideBar/note-active.svg';
import AutoGenIcon from 'src/assets/images/SideBar/AutoGen.svg';
import AutoGenActiveIcon from 'src/assets/images/SideBar/AutoGen-active.svg';
import FileIcon from 'src/assets/images/SideBar/myDocuments.svg';
import FileActiveIcon from 'src/assets/images/SideBar/myDocuments-active.svg';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
interface IconButtonProps {
  isActive: boolean;
  disabled?:boolean;
  icon: string;
  activeIcon: string;
  onClick: () => void;
  tooltip: string;
}

// Adding display name to IconButton
const IconButton: React.FC<IconButtonProps> = React.memo(({ isActive, icon, activeIcon, onClick, tooltip }) => (
  <Tooltip title={tooltip} placement="right-start">
    <button
   
      onClick={onClick}
      className={`w-12 h-12 p-3 ${isActive ? 'bg-primary' : 'bg-transparent'} rounded-xl justify-center items-center gap-2.5 inline-flex`}>
      <img src={isActive ? activeIcon : icon} alt={tooltip} />
    
      
    </button>
  </Tooltip>
));

IconButton.displayName = 'IconButton';
import { useTranslation } from 'react-i18next';
import DropdownSideBar from './DropDownSideBar';
import Avatar from 'src/modules/General/components/Avatar';

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { auth, setAuth } = useAuth();
  const [timeLeft, setTimeLeft] = useState<number>();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const avatarRef = useRef<HTMLButtonElement>(null); 
  const [lang, setLang] = useState<string>('en');

  const { i18n } = useTranslation();

  const changeLanguage = () => {
    i18n.changeLanguage(lang === 'en' ? 'fr' : 'en');
    setLang(lang === 'en' ? 'fr' : 'en');
    localStorage.setItem('language',lang === 'en' ? 'fr' : 'en')
  };

  // Memoized function to check if the route is active
  const isActiveRoute = useCallback((path: string) => pathname.includes(path), [pathname]);

  // Memoized function to handle logout
  const onLogout = useCallback(
    
    (confirmLogout = true) => { 
      setDropdownOpen(false);
      const proceedWithLogout = confirmLogout ? window.confirm('Are you sure you want to logout?') : true;

      if (proceedWithLogout) {
        setAuth({ userToken: undefined, userData: undefined });
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('language');
      localStorage.removeItem('i18nextLng');
        navigate('/user-login');
      }
    },
    [setAuth, navigate]
  );

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !avatarRef.current?.contains(event.target as Node) // Avoid closing if clicking on Avatar
      ) {
        setDropdownOpen(false); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="w-82 bg-white h-full px-4 py-8 flex items-center justify-between flex-col relative">
      <div className="w-full flex items-center justify-start flex-col gap-y-8">
        <div>
          <Logo />
        </div>
        <div className="w-full flex items-center justify-start flex-col gap-y-4">
          <IconButton
            isActive={isActiveRoute('/my-queries')}
            icon={QuerisIcon}
            activeIcon={QuerisIconActive}
            onClick={() => navigate('/my-queries')}
            tooltip={t('Upload')}
          />
          <IconButton
            isActive={isActiveRoute('/my-documents')}
            icon={FileIcon}
            activeIcon={FileActiveIcon}
            onClick={() => navigate('/my-documents')}
            tooltip={t('My Documents')}
          />
          <IconButton
            isActive={isActiveRoute('/my-emails')}
            icon={InboxIcon}
            activeIcon={InboxIconActive}
            onClick={() => navigate('/my-emails')}
            tooltip={t('Emails')}
          />
          <IconButton
            isActive={isActiveRoute('/auto-Gen')}
            icon={AutoGenIcon}
            activeIcon={AutoGenActiveIcon}
            onClick={() => navigate('/auto-Gen')}
            tooltip={t('AutoGen (Business forms)')}
          />
          <IconButton
            isActive={isActiveRoute('/note')}
            icon={NoteIcon}
            activeIcon={NoteActiveIcon}
            onClick={() => navigate('/note')}
            tooltip={t('Notes')}
          />
        </div>
      </div>
      <div className="flex items-center justify-start flex-col gap-4 relative">
        <IconButton
          isActive={isActiveRoute('/my-notifications')}
          icon={NotificationIcon}
          activeIcon={NotificationIconActive}
          onClick={() => navigate('/my-notifications')}
          tooltip={t('Notifications')}
        />
        {/* <button
          onClick={changeLanguage}
      
          className={`w-12 h-12 p-3 ${isActiveRoute('/settings') ? 'bg-primary' : 'bg-transparent'} rounded-xl justify-center items-center gap-2.5 inline-flex`}>
        
          {localStorage.getItem('language') ?localStorage.getItem('language') === 'en' ? 'EN': 'FR' : 'EN'}
        </button> */}
      <div className="flex flex-col items-start gap-4 w-full relative">
        <button
        ref={avatarRef}
          onClick={toggleDropdown}
          className="relative w-12 h-12 p-3 rounded-xl flex justify-center items-center"
        >
          <Avatar />
        </button>
        {isDropdownOpen && (
          <DropdownSideBar
            onLogout={()=>onLogout(true)}
            ref={dropdownRef}
          />
        )}
      </div>
      </div>
    </div>
  );
};

IconButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  activeIcon: PropTypes.string.isRequired,
  onClick: PropTypes.any.isRequired,
  tooltip: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default Sidebar;
